<template>
  <div>
    <!-- Add font size dropdown -->
    <select class="ql-size">
      <option value="small" />
      <!-- Note a missing, thus falsy value, is used to reset to default -->
      <option selected />
      <option value="large" />
      <option value="huge" />
    </select>
    <!-- Add a bold button -->
    <button class="ql-bold">
      Bold
    </button>
    <button class="ql-italic">
      Italic
    </button>
    <!-- Add subscript and superscript buttons -->
    <button
      class="ql-script"
      value="sub"
    />
    <button
      class="ql-script"
      value="super"
    />
    <button
      class="ql-list"
      value="ordered"
    />
  </div>
</template>

<script>
export default {
  name: 'EditorToolbar',
}
</script>
